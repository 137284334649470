/*eslint-disable */
// import quizAnswersModel from '../../Model/quizAnswers'
import request from '../../Utils/curl'

let QuizAnswers = {
  /**
   * quizAnswersList
   */
  async quizAnswersList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "quiz_answers_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at quizAnswersList() and Exception:',err.message)
    }
  },

  /**
   * quizAnswersReport
   */
   async quizAnswersReport (context, quizAnswersObj) {
    try{
      let post_data = new FormData();
    
      for (let key in quizAnswersObj) {
        if (quizAnswersObj[key] || typeof quizAnswersObj[key] == "number") {
          post_data.append(key, quizAnswersObj[key]);
        }
      }
  
      return await request.curl(context, "quiz_answers_report", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at quizAnswersList() and Exception:',err.message)
    }
  },
  /**
   * quizAnswersView
   */
  async quizAnswersView (context, quizAnswersId) {
    try {      
      let post_data = new FormData();
      post_data.append('qns_id', quizAnswersId);
      return await request.curl(context, "quiz_answers_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at quizAnswersView() and Exception:",err.message)
    }    
  },

  /**
   * quizAnswersAdd
   */
  async quizAnswersAdd (context, quizAnswersObj) {
    try{
      let post_data = new FormData();
    
      for (let key in quizAnswersObj) {
        if (quizAnswersObj[key] || typeof quizAnswersObj[key] == 'number') {
          if (typeof quizAnswersObj[key] == "object") {
            post_data.append(key, JSON.stringify(quizAnswersObj[key]));
          }
          else {
            post_data.append(key, quizAnswersObj[key]);
          }
        }
      }

      return await request.curl(context, "quiz_answers_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at quizAnswersAdd() and Exception:',err.message)
    }
  },

  /**
   * quizAnswersEdit
   */
  async quizAnswersEdit (context, quizAnswersObj) {
    try{
      let post_data = new FormData();
    
      for (let key in quizAnswersObj) {
        post_data.append(key, quizAnswersObj[key]);
      }

      return await request.curl(context, "quiz_answers_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at quizAnswersEdit() and Exception:',err.message)
    }
  },

  /**
   * quizAnswersDelete
   */
  async quizAnswersDelete (context, quizDeleteObj) {
    try{
      let post_data = new FormData();
      
      for (let key in quizDeleteObj) {
        if (quizDeleteObj[key] || typeof quizDeleteObj[key] == 'number') {
          if (typeof quizDeleteObj[key] == "object") {
            post_data.append(key, JSON.stringify(quizDeleteObj[key]));
          }
          else {
            post_data.append(key, quizDeleteObj[key]);
          }
        }
      }

      return await request.curl(context, "quiz_answers_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at quizAnswersDelete() and Exception:',err.message)
    }
  },

  /**
   * quiz Answer Explanation List
   */
   async quizAnswersExplanationList (context, userAppDetailsObj, offset) {
    try{
      let post_data = new FormData();
      
      post_data.append('app_id', userAppDetailsObj.app_id);
      post_data.append('other_user_id', userAppDetailsObj.userId)
      post_data.append('offset', JSON.stringify(offset))

      if (userAppDetailsObj.attempt) {
        post_data.append('attempt', userAppDetailsObj.attempt)
      }

      return await request.curl(context, "quiz_answers_explanation_list", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at quizAnswersExplanationList() and Exception:',err.message)
    }
  },

  /**
   * quizReportList
   */
   async quizReportList (context, userAppDetailsObj) {
    try{
      let post_data = new FormData();
      
      post_data.append('app_id', userAppDetailsObj.app_id);
      post_data.append('other_user_id', userAppDetailsObj.other_user_id)

      if (userAppDetailsObj.attempt) {
        post_data.append('attempt', userAppDetailsObj.attempt)
      }

      return await request.curl(context, "quiz_report_list", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at quizReportList() and Exception:',err.message)
    }
  },

  /**
   * quizAnswersRestore
   */
   async quizAnswersRestore (context, quizAnsRestoreObj) {
    try{
      let post_data = new FormData();
      
      for (let key in quizAnsRestoreObj) {
        if (quizAnsRestoreObj[key] || typeof quizAnsRestoreObj[key] == 'number') {
          if (typeof quizAnsRestoreObj[key] == "object") {
            post_data.append(key, JSON.stringify(quizAnsRestoreObj[key]));
          }
          else {
            post_data.append(key, quizAnsRestoreObj[key]);
          }
        }
      }

      return await request.curl(context, "quiz_answers_restore", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at quizAnswersRestore() and Exception:',err.message)
    }
  },

  /**
   * quizAnswersRestore
   */
   async quizAnswersAprsList (context, quizAnsAprsObj) {
    try{
      let post_data = new FormData();
      
      for (let key in quizAnsAprsObj) {
        if (quizAnsAprsObj[key] || typeof quizAnsAprsObj[key] == 'number') {
          if (typeof quizAnsAprsObj[key] == "object") {
            post_data.append(key, JSON.stringify(quizAnsAprsObj[key]));
          }
          else {
            post_data.append(key, quizAnsAprsObj[key]);
          }
        }
      }

      return await request.curl(context, "quiz_answers_aprs_list", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at quizAnswersRestore() and Exception:',err.message)
    }
  }
}

export {
  QuizAnswers
}
